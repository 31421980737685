import React, { useRef, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { BsCalendar2DateFill } from "react-icons/bs";
import { AiFillTrophy } from "react-icons/ai";
import { BsFillPersonFill } from "react-icons/bs";
import { BsFillTelephoneFill } from "react-icons/bs";
import "./Modal.css";
import { Zoom } from "react-awesome-reveal";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useAuth } from "../firebaseAuth/authContext";
import axios from "axios";
import darkbg5 from "../../images/shady.jpg";
import BasicSpinner from "./BasicSpinner";
const Modal = ({ data, closeModal }) => {
  const hideAlert = () => {
    const el = document.querySelector(".alert");
    if (el) el.parentElement.removeChild(el);
  };
  const [loading, setLoading] = useState(false);
  const showAlert = (type, msg) => {
    hideAlert();
    const markup = `<div class="alert alert--${type}">${msg}</div>`;
    document.querySelector("body").insertAdjacentHTML("afterbegin", markup);
    setTimeout(hideAlert, 3000);
  };

  const navigate = useNavigate();
  const { userid, currentUser } = useAuth();
  const [drop, setDrop] = useState(false);
  const [count, setCount] = useState(1);
  const [team, setTeam] = useState({
    "Member 1": "",
  });
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const teamDetailsRef = useRef(null);
  const handleRegister = async () => {
    if (!currentUser) {
      navigate("/login");
    } else {
      if (data.subtype == "solo") {
        try {
          console.log(currentUser);
        } catch (error) {
          console.error("Error creating order:", error);
        }
      } else {
        setDrop(true);
        // setVisible(False);  to make it not visible
        // teamDetailsRef.current.scrollIntoView({ behavior: 'smooth' });
        setTimeout(() => {
          if (teamDetailsRef.current) {
            teamDetailsRef.current.scrollIntoView({ behavior: "smooth" });
          } else {
            console.error("Team Details section is not rendered yet");
          }
        }, 0);
      }
    }
  };

  const handlewhilepay = async () => {
    try {
      setLoading(true);
      // Create an order for Razorpay payment
      const orderResponse = await axios.post(
        `https://aerophilia2023-8f9e.onrender.com/api/create/orderid`,
        {
          name: data.name,
          userId: userid,
          teamMembers: team,
        }
      );
      console.log(data.name);

      setLoading(false);

      if (orderResponse.data && orderResponse.data.id) {
        alert(
          "Once you successfully make the payment please wait until you get the alert message saying ,Online Payment successful"
        );
        // Initialize Razorpay
        const options = {
          key: "rzp_live_kDqZUwqGe9Q48L", // Replace with your Razorpay key
          amount: 100,
          currency: "INR",
          name: "Aerophilia 2023",
          description: "Event Registration",
          order_id: orderResponse.data.id,
          handler: async function (response) {
            const paymentData = {
              razorpay_order_id: response.razorpay_order_id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature,
              userId: userid, // Replace with the user's ID
              eventName: data.name, // Replace with the event name
              teamMembers: team,
            };

            // Verify the payment with your backend
            const verifyResponse = await axios.post(
              `https://aerophilia2023-8f9e.onrender.com/api/payment/verify`,
              paymentData
            );

            if (verifyResponse.data && verifyResponse.data.success) {
              // Payment was successful
              setPaymentSuccess(true);
              alert("Online payment successfull");
            } else {
              // Handle payment verification failure
            }
          },
        };

        const razorpayInstance = new window.Razorpay(options);
        razorpayInstance.open();
      } else {
        // Handle order creation failure
      }
    } catch (error) {
      // Handle any errors that occur during payment initiation
      console.error("Error creating order or processing payment:", error);
      setLoading(false);
    }
  };

  const shouldDisplayreg = () => {
    if (data.name === "EClash") {
      return false;
    } else {
      return true;
    }
  };

  let nae = "Pay Offline";
  //offline update
  const shouldDisplayOfflineButton = () => {
    if (data.name === "FRAMES" || data.name === "VISUAL VIBES") {
      nae = "Register";
      return true;
    } else if (data.name === "CUBE CLASH" || data.name === "BON APPETIT") {
      nae = "Pay Offline";
      return true;
    } else {
      nae = "Pay Offline";
      return true;
    }
  };

  const handlewhilenotpay = async () => {
    try {
      setLoading(true);
      // Prepare the payment data
      const paymentData = {
        userId: userid,
        eventName: data.name,
        teamMembers: team,
        // Indicate that it's an offline payment
      };

      // Make a POST request to the /payment/offline route
      const response = await axios.post(
        "https://aerophilia2023-8f9e.onrender.com/api/payment/offline",
        paymentData
      );

      if (response.data && response.data.success) {
        // Payment was successful
        // You can show a success message or perform any other actions
        setLoading(false);
        alert("Registration successful!");
      } else {
        // Handle payment verification failure
        // You can show an error message or perform other error handling actions
        alert("Offline payment verification failed.");
      }
    } catch (error) {
      console.error("Error processing offline payment:", error);
      // Handle any errors that occur during the payment process
      setLoading(false);
      alert("Error processing offline payment.");
    }
  };
  const handleChange = (e) => {
    setTeam({
      ...team,
      [e.target.name]: e.target.value,
    });
  };

  const increaseCount = (e) => {
    setTeam({
      ...team,
      [`Member ${count + 1}`]: "",
    });
    setCount(count + 1);
  };
  const decreaseCount = (e) => {
    const newTeam = team;
    if (count > 1) {
      delete newTeam[`Member ${count}`];
      setTeam(newTeam);
      setCount(count - 1);
    }
  };

  return (
    <>
      {loading ? (
        <BasicSpinner />
      ) : (
        <Zoom className=" overlay w-100 h-100 position-fixed" duration={500}>
          <div
            className="modal-container  mt-5"
            style={{
              backgroundImage: `url(${darkbg5})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              maxWidth: "100%",
            }}
          >
            <AiOutlineClose
              onClick={() => closeModal(false)}
              className="close "
            />

            <div className="modal-content ">
              <div>
                <div id="head" className="border p-1 text-center">
                  <h5 className="fs-3">{data.name}</h5>
                  <div>
                    <AiOutlineClose
                      onClick={() => closeModal(false)}
                      className="closeres"
                    />
                  </div>
                </div>
                <div className="mt-3">
                  <div>
                    <img
                      src={data.image}
                      className="img"
                      alt=""
                      style={{ height: "160px", width: "200px" }}
                    />
                  </div>

                  <div className="quick-info ">
                    <div>
                      <AiFillTrophy /> <h2>{data.prize}</h2>
                    </div>
                    <div>
                      <BsCalendar2DateFill /> <h2> {data.date}</h2>
                    </div>
                  </div>
                  <div className="contact-info">
                    <h2>ORGANIZER DETAILS:</h2>

                    {data.contactDetails.map((e, i) => (
                      <div key={i} className="organizer border p-2 mt-3">
                        <div className="fs-6">
                          <BsFillPersonFill /> <span>{e.name}</span>
                        </div>
                        <div className="tele">
                          <BsFillTelephoneFill />{" "}
                          <span>
                            <a
                              href={`tel:${e.phoneNumber}`}
                              className="text-decoration-none fs-6 text-white"
                            >
                              {e.phoneNumber}
                            </a>
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="modal-relative p-4">
                <div className="mt-2">
                  <h5>DESCRIPTION:</h5>
                  <p>{data.desc}</p>
                </div>
                {data.rules && (
                  <div className="">
                    <h5>RULES:</h5>
                    <p>
                      <ul>
                        {data.rules.map((value, index) => (
                          <li key={index} className="fs-5">
                            {value}
                          </li>
                        ))}
                      </ul>
                    </p>
                  </div>
                )}
                {data.registrations && (
                  <div className="">
                    <h5>REGISTRATION DETAILS:</h5>
                    <p className=" ">
                      <ul>
                        {data.registrations.map((value, index) => (
                          <li key={index} className="fs-5">
                            {value}
                          </li>
                        ))}
                      </ul>
                    </p>
                  </div>
                )}

                {data.venue && (
                  <div className="">
                    <h5>SCHEDULE:</h5>
                    <p className=" ">
                      <ul>
                        <li className="fs-5">{data.venue}</li>
                        <li className="fs-5">{data.time}</li>
                      </ul>
                    </p>
                  </div>
                )}

                {data.judgingCriteria && (
                  <div className="">
                    <h5>JUDGING CRITERIA:</h5>
                    <p className="   ">
                      <ul className=" ">
                        {data.judgingCriteria.map((value, index) => (
                          <li key={index} className="fs-5">
                            {value}
                          </li>
                        ))}
                      </ul>
                    </p>
                  </div>
                )}

                {data.format && (
                  <div>
                    <h5>FORMAT:</h5>
                    {Object.keys(data.format).map((key, index) => {
                      return (
                        <div key={index} className="round">
                          {key && <h4 className="fs-5">{key}</h4>}
                          <p>
                            <ul>
                              {data.format[key].map((value, index) => (
                                <li key={index} className="fs-5">
                                  {value}
                                </li>
                              ))}
                            </ul>
                          </p>
                        </div>
                      );
                    })}
                  </div>
                )}

                {data.teamReq && (
                  <div className="">
                    <h5>TEAM REQUIREMENTS:</h5>
                    <p className="   ">
                      <ul className=" ">
                        {data.teamReq.map((value, index) => (
                          <li key={index} className="fs-5">
                            {value}
                          </li>
                        ))}
                      </ul>
                    </p>
                  </div>
                )}
                {data.specialnote && (
                  <div className="">
                    <h5>Special note:</h5>
                    <p className="">{data.specialnote}</p>
                  </div>
                )}
                {data.link && (
                  <div className="note ">
                    <p className="display-6  p-1">
                      <span>NOTE:</span> Rules are subjected to changes,Refer
                      rule book for complete details
                    </p>
                  </div>
                )}
                <div className="buttons">
                  {data.link && (
                    <div>
                      <a href={data.link} target="_blank">
                        <button className="btn btn-primary fs-6 ">
                          <span>Rulebook</span>
                        </button>
                      </a>
                    </div>
                  )}
                  <div>
                    {shouldDisplayreg() && (
                      <button
                        className="btn btn-primary fs-6 "
                        onClick={handleRegister}
                      >
                        <span>Register</span>
                      </button>
                    )}
                  </div>
                  <div>
                    {!shouldDisplayreg() && <p>Registeration is closed</p>}
                  </div>
                  {data.faq && (
                    <div>
                      <a
                        href="https://drive.google.com/file/d/1LjKgZPMM6iP2UqBuG0TNYEqir3vipL9e/view?usp=sharing"
                        target="_blank"
                      >
                        <button className="btn btn-primary fs-6 ">
                          <span>FAQ</span>
                        </button>
                      </a>
                    </div>
                  )}
                </div>
                {drop && (
                  <React.Fragment>
                    <div ref={teamDetailsRef} className="drop-container">
                      <h1
                        className="drop-head"
                        style={{ color: "rgba(250, 163, 76, 0.982)" }}
                      >
                        TEAM DETAILS
                      </h1>
                      <h5
                        style={{
                          color: "rgba(250, 163, 76, 0.982)",
                          marginBottom: "20px",
                        }}
                      >
                        (include your name too)
                      </h5>
                      <form className="sign-form">
                        {/* <input type="text" name="teamName" test className="input-field" placeholder="Team Name" required onChange={(e)=>handleChange(e)}/> */}

                        {Object.keys(team).map((key, index) => {
                          return (
                            <div className="input-container ">
                              <input
                                type="text"
                                key={key}
                                name={key}
                                className="input-field text-white white-text border p-2 m-2"
                                placeholder={`Member ${index + 1}`}
                                required
                                onChange={handleChange}
                                style={{
                                  backgroundColor: "transparent",
                                }}
                              />
                            </div>
                          );
                        })}
                      </form>
                      <div className="button-list">
                        {count < data.maxlimit ? (
                          <button
                            className="glow-on-hover p-2 fs-4"
                            style={{ width: "15rem" }}
                            onClick={(e) => increaseCount(e)}
                          >
                            Add Member
                          </button>
                        ) : (
                          <button className="glow-on-hover p-2 fs-4">
                            Max limit reached
                          </button>
                        )}
                        <button
                          className="glow-on-hover p-2 fs-4"
                          style={{ width: "15rem" }}
                          onClick={(e) => decreaseCount(e)}
                        >
                          Remove Member
                        </button>
                        {data.mode == "online" && (
                          <button
                            className="glow-on-hover p-2 fs-4"
                            style={{ width: "15rem" }}
                            id="rzp-button1"
                            onClick={handlewhilepay}
                          >
                            Pay Online
                          </button>
                        )}
                        {shouldDisplayOfflineButton() && (
                          <button
                            className="glow-on-hover p-2 fs-4"
                            style={{ width: "15rem" }}
                            id="rzp-button1"
                            onClick={handlewhilenotpay}
                          >
                            {nae}
                          </button>
                        )}

                        {console.log(count)}
                      </div>
                    </div>
                    <div></div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </Zoom>
      )}
    </>
  );
};

export default Modal;
