import React from 'react';

const PP = () => {
  return (
    <div>
      <p className='text-black'>This Privacy Policy applies to the Aerophilia 2023
        Aerophilia 2023 recognizes the importance of maintaining your privacy. We value your
        privacy and appreciate your trust in us. This Policy describes how we treat user
        information we collect on https://www.aerophilia.in and other offline
        sources. This Privacy Policy applies to current and former visitors to our website and to
        our online customers.
        By visiting and/or using our website, you agree to this Privacy Policy.
        Aerophilia 2023 is a property of Team Challengers, Sahyadri College of Enginnering and management,Adyar,Karnataka.575007.
        Information we collect
        Contact information. We might collect your name, email, mobile number, phone number,
        street, city, state, pincode, country and ip address.
        Payment and billing information. We might collect your billing name, billing address and
        payment method when you register for participation of an event. We NEVER collect your
        credit/debit card number or credit/debit card expiry date or other details pertaining to
        your credit/debit card on our website. Credit/debit card information will be obtained and
        processed by our online payment gateway.
        Information you post. We collect information you post in a public space on our website
        or on a third•party social media site belonging to Aerophilia 2022.
        Demographic information. We may collect demographic information about you, events
        you like, events you intend to participate in Aerophilia 2022, or any other information
        provided by you’re during the use of our website. We might collect this as a part of a
        survey also.
        Other information. If you use our website, we may collect information about your IP
        address and the browser you're using. We might look at what site you came from,
        duration of time spent on our website, pages accessed or what site you visit when you
        leave us. We might also collect the type of mobile device you are using, or the version of
        the operating system your computer or device is running.
        We collect information in different ways.
        We collect information directly from you. We collect information directly from you when
        you register for an event. We also collect information if you post a comment on our
        websites or ask us a question through phone or email.
        We collect information from you passively. We use tracking tools like Google Analytics,
        Google Webmaster, browser cookies and web beacons for collecting information about
        your usage of our website.
        We get information about you from third parties. For example, if you use an integrated
        social media feature on our websites. The third•party social media site will give us certain
        information about you. This could include your name and email address.
        Use of your personal information
        We use information to respond to your requests or questions. We might use your
        information to confirm your registration for an event or contest.
        We use information to improve our services. We might use your information to
        customize your experience with us. This could include displaying content based upon
        your preferences.
        We use information to look at site trends and participants interests. We may use your
        information to make our website / events better. We may combine information we get
        from you with information about you we get from third parties.
        We use information for security purposes. We may use information to protect our
        company, our customers, or our websites.
        We might also tell you about new events.
        We use information to send you transactional communications. We might send you
        emails or SMS about your account and registration details.
        We use information as otherwise permitted by law.
        We will never share information with any other third•parties
        We may share information if we think we have to in order to comply with the law or to
        protect ourselves. We will share information to respond to a court order or subpoena. We
        may also share it if a government agency or investigatory body requests. Or, we might
        also share information when we are investigating potential fraud. .
        Email Opt•Out
        You can opt out of receiving our marketing emails. To stop receiving our promotional
        emails, please email to info@aerophilia.in. It may take about ten days to
        process your request.
        Third party sites
        If you click on one of the links to third party websites, you may be taken to websites we
        do not control. This policy does not apply to the privacy practices of those websites. Read
        the privacy policy of other websites carefully. We are not responsible for these third party
        sites.
        Grievance Officer
        -------------
        Updates to this policy
        This Privacy Policy was last updated on ----- From time to time we may change
        our privacy practices. We will notify you of any material changes to this policy as
        required by law. We will also post an updated copy on our website. Please check our site
        periodically for updates.
        Any disputes will come under Jurisdiction ------
        If you choose to visit the website, your visit and any dispute over privacy is subject to
        this Policy and the website's terms of use. In addition to the foregoing, any disputes
        arising under this Policy shall be governed by the laws of India. </p>
        <div></div>
    </div>
  )
}

export default PP;
