import React from 'react'
import Img1 from '../../sponsors/Coastals.jpg'  // 2
import Img2 from '../../sponsors/Dassault.png'
import Img3 from '../../sponsors/JMV.png'
// import Img4 from '../../sponsors/kc.jpg'  // 2
import Img5 from '../../sponsors/MsPai.jpg'  // 3
import Img6 from '../../sponsors/prasad.png'
import Img7 from '../../sponsors/slc.PNG'
import Img8 from '../../sponsors/Sudindra.jpg'
// import Img9 from '../../sponsors/sulthan.png'
import Img10 from '../../sponsors/surabhi.png'  
import Img11 from '../../sponsors/travas.jpg' // 3
import Img9 from '../../sponsors/vicky.png'  // 3
import Img13 from '../../sponsors/hangyo.jpg'    // 1
import './Home.css'

const Sponsors = () => {
    const images = [Img13, Img1, Img5, Img11, Img10, Img2, Img3, Img6, Img9, Img8, Img7];
  
    const renderRows = () => {
      const rows = [];
      const rowCount = [1, 2, 3, 6];
      let imageIndex = 0;
  
      rowCount.forEach((count, rowIndex) => {
        const rowImages = images.slice(imageIndex, imageIndex + count);
        imageIndex += count;
  
        rows.push(
          <div key={rowIndex} className='row small' style={{ display: 'flex', justifyContent: 'center' }}>
            {rowImages.map((image, imgIndex) => {
              let specificStyle = {};
  
              if (image === Img1 || image === Img11) {
                specificStyle = { width: '80%', height: '78%',left:'10%',top:'10%' }; // Adjust width and height as needed
              }
  
              return (
                <div key={imgIndex} className={`col-md-${12 / count} pentagon-logo m-1  `}>
                  <img
                    src={image}
                    alt={`sponsor${rowIndex + imgIndex}`}
                    className='img-fluid'
                    style={{ ...specificStyle }}
                  />
                </div>
              );
            })}
          </div>
        );
      });
  
      return rows;
    };
  
    return (
      <div className='p-4' style={{minHeight:'100vh'}}>
        <h1 className='text-white text-center'>Sponsors</h1>
        <div className='container p-3 mt-5'>
            {renderRows()}
        </div>
      </div>
    );
  };
  
  export default Sponsors;
  


