import React from 'react';
import './BasicSpinner.css'; // Import your CSS file for styling

const BasicSpinner = () => {
  return (
    <div className="processing-overlay">
      <div className="basic-spinner"></div>
      <p className="processing-text">Processing...</p>
    </div>
  );
};

export default BasicSpinner;
