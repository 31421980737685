import img from "../../images/SkyDive.webp";
import ui from "../../images/ui.webp";
import code from "../../images/code.webp";
import sky from "../../images/sky.webp";
import frames from "../../images/frames.webp";
import cad from "../../images/cad.webp";
import sumo from "../../images/sumo.webp";
import reel from "../../images/reels.webp";
import goal from "../../images/goal.webp";
import eclash from "../../images/eclash.jpeg";
import Lift from "../../images/Lift-Off.webp";
import line from "../../images/line.webp";
import momentum from "../../images/momentum.webp";
import terraglide from "../../images/terraglide.webp";
import firepower from "../../images/Firepower Poster.png";
import codeblaze from "../../images/codeblaze.png";
import cube from "../../images/cube.webp";
import bon from "../../images/bon.webp";
export const eventData = [
  {
    type: "Technical",
    name: "UI BATTLES",
    day: "1",
    time: "1.30 PM",
    venue: "Learning center",
    date: "8th Dec",
    maxlimit: 2,
    mode: "online",
    image: ui,
    prize: "₹10,000",
    registrations: [
      "Spot registrations are available.",
      "Registration fee: ₹ 300 per team",
    ],
    teamReq: [
      "Each team should consist of a maximum of 2 members.",
      "Solo participation is also allowed.",
      "Team members can be from different school or college.",
      "Participants should get their school/college ID card.",
      "Any number of teams from a school/college is allowed.",
    ],
    desc: "Hello coders! Are you ready for an exciting challenge that aligns with your passion for web development. Welcome to the Web Dev code the logic & Page Cloning Challenge, where your fundamental coding skills will be put to the ultimate test.",
    smallDesc: "Code Clone Conquer",
    format: {
      "Round-1  CODE THE LOGIC :": [
        "Teams will be given a set of HTML snippets. Each snippet will have an associated description indicating the task that needs to be performed. Participants have to choose the correct javascript code to satisfy the functionality demanded.(MCQ type).There will be only one correct option",
        "There will be no elimination in this round",
        "Teams will be scored on the number of correct options they select",
        " If 2 teams get the same score, then the team which submitted first will be given higher priority.(only in case there is a tie after the second round)",
        "This score will be carried forward for the next round",
        "It will carry only 30% of weightage",
      ],
      "Round-2 - PAGE CLONING CHALLENGE :": [
        "Teams will be provided with a figma design which they have to clone using HTML, CSS/any other CSS library and javascript only..",
        " Teams have to clone the figma design in the specified duration",
        "Teams will be evaluated based on how closely their web page aligns with the provided figma design",
        "Participants will be provided with internet to refer only in this round",
        "It will carry 70% of weightage",
      ],
    },

    contactDetails: [
      {
        name: "Babith",
        phoneNumber: "+91 8971206807",
      },
      {
        name: "Srajan",
        phoneNumber: "+91 8050215589 ",
      },
    ],
    link: "https://drive.google.com/file/d/14EBWCF-1VhehkGq8hnLjVJeWB9VhR6jE/view?usp=drivesdk",
  },
  {
    type: "Technical",
    name: "CODE QUEST",
    day: "2",
    time: "9.30 AM",
    venue: "Leraning center",
    date: "9th Dec",
    maxlimit: 3,
    mode: "online",
    image: code,
    prize: "₹10000",
    registrations: [
      "Spot registrations are available.",
      "Registration fee: ₹ 300 per team",
    ],
    teamReq: [
      "All the team members must be full-time students at an accredited University/College/School.",
      "Students from multiple Colleges can form a team.",
      "There are no restrictions on the number of teams from the same Institute.",
      "The team can have 3 members in total",
      "Individual Participation is also allowed",
      "Each participant may only be a part of one team",
      "Participants should get their school/college ID card",
    ],
    desc: "Want to show off your coding skills? Then you have come to the right place. Here you can show your skills by completing the given challenges. Face against your peers to test your current level. Make sure to keep your foundations of coding skills strong.",
    smallDesc: "Compete Win Prove",
    format: {
      "Round-1  THE PRELIMINARY TEST :": [
        "In this round, 25 MCQ questions related to computer science will be given to the participants, who are supposed to solve them within 25 mins.",
      ],
      "Round-2 :": [
        "A group of codes will be shared to all the teams (5 questions). The participants will have to find the total number errors in the given code (in python or C or C++) and write the line number in which the error is present. Points will be allocated to the teams based on the number of errors they find. Teams will have to complete the event within the given time limit.",
      ],
      "Round-3 :": [
        "In this round, 4 coding questions will be given to the participants, who are supposed to solve the problem within a given time limit. The participants can code in one of the languages from the given options (python/C/C++).",
      ],
    },

    contactDetails: [
      {
        name: "ANUDEEP K K",
        phoneNumber: "+91 8762339086 ",
      },
      {
        name: "Sankshipth Shetty",
        phoneNumber: "+91 6362531671",
      },
    ],
    link: "https://drive.google.com/file/d/11UJzIOY1DbvP-ic_cGaBbyWB-E6gNk_n/view?usp=drivesdk",
  },
  {
    type: "Technical",
    name: "SKY DIVE",
    day: "1 and 2",
    time: "1 PM",
    venue: "BACK GROUND",
    date: "8th and 9th Dec",
    maxlimit: 5,
    faq: true,
    mode: "online",
    image: sky,
    prize: "₹75000",
    registrations: ["Registration fee: ₹ 2500 per team"],
    teamReq: [
      "Maximum 5 members in a team.",
      "Participation allowed for school & college students with valid ID proof.",
      "Team members can be from different school or colleges",
    ],
    desc: "Team Challengers of Sahyadri College of Engineering and Management, Mangalore invites all university students to participate in Aerophilia ‘23. The contest will provide a real-world aircraft design experience for engineering students by allowing them to validate their analytical skills. Student teams will design, fabricate, and demonstrate the flight capabilities of an unmanned, electric-powered, radio-controlled aircraft that can best meet the specified mission profile. The goal is a balanced design possessing good, demonstrated flight handling qualities, and practical and affordable manufacturing requirements. Check the rules package carefully as items and approaches that were legal in past years may not be legal for this contest year. Only the contents of this year's Rules package along with the current FAQ and Q&A documents hold bearing on the requirements and/or allowances for the current contest year. It is the responsibility of the teams to know and follow all provided rules, the FAQ and Q&A, and all contest day briefings. Winning teams will receive prizes worth ₹75,000.",

    smallDesc: "The sky's calling!",
    format: {
      "FLIGHT LINE COURSE:": [
        "The orientation (direction) of the flight course will be adjusted based on the prevailing winds as determined by the Flight Line Judge. The flight course will be positioned to maintain the greatest possible safety for personnel and facilities. The nominal flight course will be uploaded on the official Aerophilia website",
        "Note: The final flight course may vary depending on the weather and other physical aspects.",
      ],
      "DESIGN REPORT/ABSTRACT SUBMISSION:": [
        "Design Reports will be submitted online via email to submission@aerophilia.in. The last date for abstract submission is on 25.11.2023 by 23:59 Indian Standard Time.",

        "Reports will be judged “as received”. No corrections/additions/changes will be allowed by the organisers so check your reports carefully before submitting them. Once a Report is submitted, no changes are allowed. Submission of Reports is electronic only (no hard copy required). The details for the electronic format and submission are at the end of the report section in this rules document.",
      ],
      "MISSION AND VEHICLE DESIGN:": [
        "Mission 1 - Spot landing",
        "Mission 2 - Manoeuvring",
        "Mission 3 - Stunt round",
      ],
      "MISSION SEQUENCE:": [
        "Aircraft must be capable of performing all required missions.",
        "The Missions must be flown in order.",
        "A new mission cannot be flown until the team has obtained a successful score for the preceding mission.",
        "The aircraft must be flown in the same configuration for all three missions. For example, if any pod is carried under the wing, then that pod must be flown in all three missions.",
        "The aircraft must complete a successful landing at the end of each mission. If the aircraft takes any serious damage during the landing, it would be considered a crash landing.",
      ],
      "MISSIONS:": ["Below is the list of missions"],

      " Mission 1 - Spot landing:": [
        "Teams must take off their plane, fly it for at least 120 seconds, and land(touchdown) it properly at the designated area, i.e., a circle of 3 meters in diameter.",
        "No time limit for this round.",
        "If the plane crashes, then 5 points will be deducted.",
        "The pilot is allowed to move anywhere in the arena during Mission 1",
        "Time starts when the aircraft throttle is advanced in the first attempt.",
        "Teams must complete a successful landing to obtain a score and successfully complete mission 1",
        "Basically, Takeoff + 2 Minutes fly + Landing",
        "M1 = 20 for a successful mission. (10 for a successful flight and 10 for landing in the specified area)",
      ],
      "Mission 2 - Maneuvering": [
        "The airplane must maneuver through a series of hoops, which will be shown on the day of the event.",
        "The pilot is allowed to move around and must stay in a fixed spot in the arena.",
        "A time window of 5 minutes will be provided, during which the teams can complete any number of laps of the set course.",
        "The scoring will take place depending on the number of laps completed.",
        "In mission 2, a lap will be considered successful only when the airplane passes through all the hoops in the given chronological order.",
        "Teams must complete a successful landing to obtain a score and successfully complete Mission 2.",
        "Scoring: M2 = 10 * n where n stands for the number of successful laps.",
      ],
      "Mission 3 - Stunts": [
        "Evaluation will be based on the time of flight and the number of stunts performed by the participant.",
        "Time duration allocated for each team is 10 minutes.",
        "Stunts' performance will be the first criterion for evaluation",
        "Time of flight will be considered only if 2 or more teams get equal marks. The fastest team will be considered.",
        "If a plane crashes, then only 70 percent of points earned from stunts will be awarded.",
        "A team can perform any number of stunts according to their abilities",
        "Evaluation Scheme: Total points = Stunt points",
        "Maximum points awarded in this round is 50 points.",
        "No stunt can be performed more than once.",
      ],
    },

    specialnote: "No using flight configurator",

    contactDetails: [
      {
        name: "Sushan Shetty",
        phoneNumber: "+91 6360298756",
      },
      {
        name: "K Anup Pai",
        phoneNumber: "+91 9731189986",
      },
    ],
    // link: "https://drive.google.com/file/d/1BFSzdzoPoZ5y_evfm1O7Qfg46ifb6UiI/view?usp=sharing",
    link: "https://drive.google.com/file/d/1JomEGBmatgnGNmDJIWBLRgK9CPfuQcXc/view?usp=sharing",
  },
  {
    type: "Technical",
    name: "TERRAGLIDE",
    day: "1 and 2 ",

    time: "11 AM",
    venue: "Canteen ground ",
    date: " 8th and 9th Dec",
    maxlimit: 4,
    mode: "online",
    image: terraglide,
    prize: "₹25000",
    registrations: [
      "Maximum 4 members in a team.",
      "Participation allowed for school & college students with valid ID proof.",
    ],
    teamReq: [
      "There are no specific requirements for the team or the team members.",
    ],
    desc: "Welcome to the exhilarating event of Aerophilia’23 TerraGlide, an event that pushes the boundaries of engineering creativity and precision. Hosted at the Sahyadri College of Engineering and Management grounds, TerraGlide is a unique competition that challenges participants to design, build, and pilot hovercraft models through an intricate racetrack filled with obstacles and time trials.",
    smallDesc: "Glide to Glory",
    format: {
      "Problem Statement:": [
        "A hovercraft is a vehicle that floats on a cushion of high-pressurised air and propels itself on this air cushion using a thrust-producing mechanism. Participants are required to design and build a remotely operated electric RC Hovercraft to navigate a given course. The craft that can complete the track in the shortest time ( Round 1 ) and secure the maximum points from the obstacle course ( Round 2 ) will be declared the winner. ",
      ],
      "Round 1: Time Trial :": [
        "With a set time of 8 minutes, teams embark on a time trial race. The total number of laps done in this time interval will be considered.",
        "2 attempts will be awarded for each team in this round.",
        "Each lap will have a weightage of 10 Points ie. (n*10) where n is the number of laps covered.",
        "A team must reach the halfway point to earn points for a lap; otherwise, no points are awarded.",
        "50% of the scores from this round will be considered.",
        "If a team relocates their hovercraft, a penalty of 3 sec will be added.",
        "Each team will have 2 attempts for this round.",
      ],
      "Round 2:Navigating Obstacle Havoc": [
        "In this round, teams are challenged to navigate a demanding obstacle course using their hovercraft within a strict time limit of 8 minutes. The total number of points that can be scored in this round is 100. Each team will be provided with 2 attempts",
      ],
      "Obstacles Points Assignment:": [
        "Jelly Stone (15 points): Teams must skillfully navigate their hovercraft over a section of jelly stones without causing any damage or getting stuck.",
        "Pool of Water (30 points): Successfully traversing through a pool of water will earn teams the maximum points.",
        "Sandpit (10 points): Teams need to cross a sandy area while maintaining control and speed.",
        "Sea-saw (20 points): Hovercrafts must carefully balance on a sea-saw structure without tipping over or causing a collision.",
        "Zig Zag Stone (Hurdles) (25 points): Teams will face a zig-zag pattern of stone hurdles, and they must navigate through them with precision.",
        "Note: Teams are allowed to skip any of the obstacles if they feel that it can damage their hovercraft, no points will be given for attempting the obstacle.",
      ],
      "Scoring Guildlines:": [
        "Points will be awarded based on the successful completion of each obstacle as specified above.",
        "Points will be deducted (-3 points) if any part of the hovercraft touches certain corners or boundaries of the course.",
        "If a team relocates their hovercraft, a penalty of 3 sec will be added.",
        "The team can take any number of laps until the timer ticks out of time.",
        "50% of the score is taken into consideration.",
      ],

      "Winner Determination:": [
        "The team with the highest aggregate score, which includes points earned from previous rounds, and the least time taken to complete the round will be declared the winner of the hovercraft event.",
      ],
      "Specification for the hovercraft:": [
        "The hovercraft will undergo the following inspection on the event day.",
        "The l*b*h of the hovercraft needs to be under (40*35*35)cm.",
        "Brushless motors with a rating of no more than 2700 KV can be used. Participants should have the Motor model with them to prove the KV value when asked to do so.",
        "ESC's current ratings should not exceed 40 A.",
        "The hovercraft should be powered by rechargeable batteries only.",
        "A power supply (battery) above 12 Volts (3s) cannot be used.",
        "The hovercraft must not have any hazardous or sharp elements that harm other hovercrafts.",
        "Violation of any of the above rules will lead to the disqualification of the team/individual.",
      ],
    },

    contactDetails: [
      {
        name: "Amog",
        phoneNumber: "+91 6363128809",
      },
    ],
    link: "https://drive.google.com/file/d/18oMlgPPwchMqZ1_j6ay5Y7ug9utBQ3NA/view?usp=sharing",
  },
  {
    type: "Technical",
    name: "MOMENTUM",
    day: "1",
    time: "1.30 PM",
    venue: "Front Ground",
    date: "8th Dec",
    maxlimit: 4,
    mode: "online",
    image: momentum,
    prize: "₹5000",
    registrations: [
      "Spot registrations are available.",
      "Registration fee: ₹ 400 per team",
    ],
    teamReq: [
      "Team should not exceed more than 4 people.",
      "Team members should be from the same institution.",
      "Each participant may only be a part of one team.",
      "Any number of teams from a school/college is allowed.",
    ],
    desc: "How exhilarating will it be to build and fly your own rocket? Get ready to launch into a world where innovation takes flight, and engineering prowess meets the power of H2O! Join us as we witness rockets of all shapes and sizes, each meticulously crafted to soar to the sky, powered only by the might of water and the brilliance of engineering minds. Welcome to the most exhilarating event of the year, where creativity soars higher than ever before, MOMENTUM - Aerophilia23's Water Rocket Competition! ",
    smallDesc: "Ride the tide, laws applied!",
    format: {
      task: [
        "Teams are required to construct a water propelled rocket pressurized with air using bottles. These will be then tested for their efficiency and working against each other.",
      ],
      specifications: [
        "The total capacity of the rocket shouldn't exceed 2.5 liters.",
        "Boosters and staging of the rocket are allowed.",
        "Total capacity of the booster and stage should not exceed 1 liter. If boosters and stage are included, the total capacity of the rocket shouldn’t exceed 3.5 liters.",
      ],
      "Round-1  How Far?:": [
        "In this round, teams must launch the rockets and try to obtain the maximum score.",
        "Teams will get 10 points for each 10 meters of the rocket's horizontal flight distance",
        "Rockets which don't go further than 20 meters will not fetch any points",
        "Rockets which go more than 100 meters will get 20 points for consecutive 10 meters.",
      ],
      "Round-2 - Hit the target :": [
        "In this round, the rocket launched should hit an area marked at a distance of 65m.",
        "The target area will be in circular shape with three regions.",
        "Innermost region of diameter 10 meters (30 points)",
        "Middle region of diameter 15 meters (20 points)",
        " Outermost region of diameter 20 meters (10 points)",
        "Rockets that do not touch the target will receive a negative score of 20 points.",
      ],
    },

    contactDetails: [
      {
        name: "Thejaswi",
        phoneNumber: "+91 8296584492 ",
      },
    ],
    link: "https://drive.google.com/file/d/1qizqZzWa7E-ZhsOcvgPRb_NuskeracIP/view?usp=sharing",
  },
  {
    type: "Technical",
    name: "LIFT OFF!",
    day: "1 & 2",
    time: "1.30 PM",
    venue: "Ground beside the hostel",
    date: "8th and 9th Dec",
    maxlimit: 5,
    mode: "online",
    image: Lift,
    prize: "₹30000",
    registrations: ["Registration fee: ₹ 1000 per team"],
    teamReq: [
      "Maximum 5 members in a team.",
      "There are no specific requirements for the team or the team members.",
    ],
    desc: "At the Drone Racing Event, students will design, fabricate, and demonstrate the flight capabilities of racing drones that can excel in meeting the specified mission profile. Skilled pilots will navigate their agile drones through intricate obstacle courses, showcasing cutting-edge engineering, precise control, and fierce competition that highlight the pilots' aerial prowess. If you're looking to be the first to pilot your drone in the race, get there early! Winning teams will receive prizes worth ₹30,000 ",
    smallDesc: "Navigate,Accelerate,Dominate-Master the Skies!",
    format: {
      "COMPETITION RULES:": [
        "The competition will have 3 rounds.",
        "First round will be an obstacle course, followed by a drag race. ",
        "In the first round, participants will have two chances to fly their drones, and the best performance will be considered.",
        "Third round is a special round announced during the event.",
        "No participants are allowed inside the arena. ",
        "Trial/practice runs in the arena are not allowed.",
        "All teams are advised to keep a pair of charged batteries.",
      ],
    },

    contactDetails: [
      {
        name: "Chirag R.P",
        phoneNumber: "+91 7975660223",
      },
      {
        name: "Shayaan Fayaz ",
        phoneNumber: "+91 8095686687",
      },
    ],
    link: "https://drive.google.com/file/d/1hWZw83mXQHQAlDwF7oR4SzGZW4jwDYWQ/view?usp=sharing",
  },
  {
    type: "Technical",
    name: "LINE FOLLOWER",
    day: "1",
    time: "9 AM",
    venue: "Mech building Seminar Hall",
    date: "8th Dec",
    maxlimit: 5,
    mode: "online",
    image: line,
    prize: "₹10000",
    registrations: [
      "Spot registrations are available.",
      "Registration fee: ₹ 500 per team",
    ],
    teamReq: [
      "All the team members must be full-time students at an accredited University/College/School.",
      "There are no restrictions on the number of teams from the same Institute.",
      "The team should contain 3-5 members in total.",
    ],
    desc: "A competition played by one or two teams at a time, with each team consisting of one wireless bot. The objective is to build a bot capable of completing various challenges along the way in the shortest period of time. The competition will test the robot's speed, accuracy, and ability to handle complex track layouts including intersections, curves, and junctions",

    smallDesc: "Let's tRACE!",
    format: {
      "Round-info": [
        "The competition will consist of 3 rounds.",
        "Each round will have a different map which will be disclosed on the day of the event.",
        "Round-specific rules will be explained just before the start of that particular round.",
        "All the rounds will have a black line/trajectory on a white arena.",
      ],
    },

    contactDetails: [
      {
        name: "AKSHAY SHET",
        phoneNumber: "+91 9449384765",
      },
    ],
    link: "https://drive.google.com/file/d/1cH0LcMKuQwna7rcmB2B61KOL6uz2Qn4k/view?usp=sharing",
  },
  {
    type: "Technical",
    name: "GOAL IN",
    day: "1",
    fee: 500,
    time: "9 AM",
    venue: "Badminton court (main block)",
    date: "8th Dec",
    maxlimit: 5,
    mode: "online",
    image: goal,
    prize: " 10000/-",

    registrations: [
      "Spot registrations are available.",
      "Every team has to pay a registration fee of ₹500/- in order to register successfully.",
    ],
    teamReq: [
      "All the team members must be full-time students at an accredited University/College/School.",
      "There are no restrictions on the number of teams from the same Institute.",
      "The team can have a maximum of 5 members and a minimum of 3.",
    ],

    desc: "Two teams will put their distinctive bots against each other on a simulated soccer field, trying to score goals while stopping their opponent from doing the same.",
    smallDesc: "The Ultimate soccer showdown",

    format: {
      "BOT SPECIFICATIONS :": [
        "The weight of the bot must not be more than 5 kg.",
        "The bot's measurements must be 30 cm in length and 30 cm in width. There will be a 10% tolerance allowance for both weight and size. ",
        "Any contravention of the above-given specification will lead to disqualification. ",
        " Bots can be wired or wireless.",
        "Wires must be long enough to reach the corners of the arena.",
        "Teams are not allowed to utilize ready-made bots like those made of Lego bricks",
      ],
    },

    contactDetails: [
      {
        name: "RANJEET B",
        phoneNumber: "+918050792346",
      },
    ],
    link: "https://drive.google.com/file/d/1_HogF09EId1FJWlc6StKtdO19LtWq4tu/view?usp=sharing",
  },
  {
    type: "Technical",
    name: "BOT SUMO",
    day: "2",

    time: "9 AM",
    venue: "Badminton court (main block)",
    date: "9th Dec",
    maxlimit: 5,
    mode: "online",
    image: sumo,
    prize: "₹10000",
    registrations: [
      "Spot registrations are available.",
      "Every team has to pay a registration fee of ₹500/- in order to register successfully.",
    ],
    teamReq: [
      "All the team members must be full-time students at an accredited University/College/School.",
      "Students from multiple Colleges can form a team.",
      "There are no restrictions on the number of teams from the same Institute.",
      "The team should contain 3-5 members in total.",
      "Each participant may only be a part of one team.",
    ],
    desc: "The competition will involve two limited sized contesting Sumo bots which will compete within a circular arena. The purpose of the competition will be for one robot to eliminate the other by means of pushing it out of the arena within the time limit. ",
    smallDesc: "Make the bot fight for its life",
    format: {
      "BOT SPECIFICATIONS :": [
        "The weight of the robot should not exceed the limit of 5 Kg.",
        "The dimensions of the bot should not exceed the limit: width: 30 cm length: 30 cm height: 30 cm",
        "A pardon of tolerance in the amounts of 10% will be given in the case of the dimensions and weight.",
        " Any discrepancy in the dimensions will lead to the team disqualification.The bot cannot be made using ready to assemble kits ",
        "Bots can be either wired or wireless.",
      ],
      "ROUND RULES": [
        "The opposition Team will be chosen by drawing lots",
        " If odd number of Bots are present then one Bot will directly go to the next round (Only in the PRELIMINARY).",
        "There will be three sessions, PRELIMINARIES, SEMIFINAL and FINAL.",
        "One who wins the toss will choose the side of the arena.",
        "The winner will be the bot left functioning in the arena.",
        "If a bot falls out of the arena after it has pushed its opponent is considered as out. (Note: No other method of attack is allowed other than pushing, if found will be disqualified).",
        "Each round will be of 5mins.",
        "In case that both the bots are unable to eliminate each other over a period of 5 minutes, the bot which has successfully performed more attacks will be considered as the winner of the round",
        "The bot can be controlled by only one person of the team (for next round it can be changed)",
        "The winners of each session will advance onto the next.",
        "The judge's verdict should be considered final and no arguments will be entertained.",
      ],
    },

    contactDetails: [
      {
        name: "BHVAISH RAI B: ",
        phoneNumber: "+919591002456",
      },
    ],
    link: "https://drive.google.com/file/d/1NLTjUqciW0LfBRqpr53za2Wc6XtSfAF0/view?usp=drivesdk",
  },
  {
    type: "Technical",
    name: "CAD-CARNIVAL",
    day: "1",
    time: "1.30 PM",
    venue: "CAD LAB(Mechanical Department)",
    date: "8th Dec",
    maxlimit: 3,
    mode: "online",
    image: cad,
    prize: "₹6000",
    registrations: [
      "Spot registrations are available.",
      "Registration fee: ₹300 per team",
    ],
    teamReq: [
      "All the team members must be full-time students at an accredited University/College/School.",
      "Students from multiple Colleges can form a team.",
      "There are no restrictions on the number of teams from the same Institute.",
      "The team should have 2-3 members in total.",
      "Individual participation is allowed",
      "A team can have a maximum of 3 participants",
      "Each participant may only be a part of one team.",
    ],

    desc: "The competition involves creating a replica of a CAD model enclosed within a cube provided to the contestants. The model given will only have TWO dimensions at the beginning of the event. The other dimensions will be provided after a specific interval of time, one by one. The rest of the dimensions must be self-estimated.The team with the closest resembling CAD model to the reference model wins",
    smallDesc: "CAD competetion",
    format: {
      "Round-info": [
        "The CAD Model enclosed in a cube will be presented on the screen.",
        "A copy of the model having a top, front, and isometric view with no dimensions will be given in hardcopy to each team.",
        "The Model on the screen will have only 2 dimensions for the beginning.",
        "The other dimension will be provided with a time gap of 1 hour or 30 mins, depending on the importance of the dimension.",
        "Only 75% of the dimensions are provided until the end of the event.",
        "Material specifications will be given at the last hour of the competition.",
        "Contestants can make the model on the given PC or on their own laptop.",
      ],
    },
    specialnote:
      "Team Will get the licenses one day after the registration. On spot registration  team will only get one pc with Solid Works.",

    contactDetails: [
      {
        name: "ROHAN BALAMKAR",
        phoneNumber: "+91 8618983737 ",
      },
    ],
    link: "https://drive.google.com/file/d/1-_9DJli9Yy4i5ki6zf1h9702c39oklcy/view?usp=drivesdk",
  },
  {
    type: " Non Technical",
    name: "FRAMES",
    day: "1 and 2",
    fee: 200,
    time: "Throughout the day",
    venue: "",
    date: "8th and 9th Dec",
    maxlimit: 1,
    image: frames,
    prize: "Exciting prices",
    registrations: ["Spot registrations are available.", "Free registration"],
    teamReq: [
      "Individual Registration.",
      "Participants can use either a professional camera or a phone for the competition.",
    ],
    desc: "Frames is a photography contest where photographers can showcase their skills by submitting stunning images captured during Aerophilia '23. You don't need extensive Photoshop expertise or a high-end professional camera to capture great photos. With basic photography techniques, you can create exquisite photos, even using just your smartphone.",
    smallDesc: "Capture timeless memories",
    format: {
      "Rules and Regulations :": [
        "The competition will be conducted for two days of the event.",
        "The competition is strictly for Photos and not Videos.",
        "Photos must be your own work.",
        "The submitted photo must not be larger than 10MB.",
        "Only digital manipulation that does not alter the original content of the image is allowed. This includes, but is not limited to, cropping, Levels/Curves adjustments, minor dodging and burning, and conversion to greyscale or sepia.",
        "The 3 best photos must be submitted through email as a Google Drive file.",
        "Each participant must select one photo and post it on their Social Media tagging @aerophilia_2023 and @sahyadri_college and using the #Aerophilia_2023 each day for the span of the event.",
        "The winners will be announced on the ﬁnal day of the event.",
        " Each participant will get an option to submit all their photos or even videos shot during the event on the ﬁnal day.",
      ],
    },

    contactDetails: [
      {
        name: "VIVIN LOBO",
        phoneNumber: "+91 78924 28715",
      },

      {
        name: "SATHWIK SHETTY",
        phoneNumber: "+91 98456 04487",
      },
    ],
    link: "https://drive.google.com/file/d/14DIKUA2F_qY51i5W6blsyNiVEHBfU-eD/view?usp=drivesdk",
  },
  {
    type: "Non Technical",
    name: "VISUAL VIBES",
    day: "1 and 2",
    fee: 200,
    time: "Througout the day",
    venue: "",
    date: "8th and 9th Dec",
    maxlimit: 1,

    image: reel,
    prize: "Exciting prices",
    registrations: ["Spot registrations are available.", "Free registration"],
    teamReq: [
      "Individual Registration.",
      "Participants can use any camera they prefer: professional, GoPro, drone, or smartphone.",
    ],
    desc: "Visual Vibes is a reel-making contest where participants submit short videos created during Aerophilia '23. You don't need to be an expert in video editing or own an expensive camera to make impressive reels with stunning visual effects. Simple techniques and even a basic smartphone can help you create captivating videos.",
    smallDesc: "Role,Camera,Action:Your story,our screen",
    format: {
      "Rules and Regulations :": [
        "The competition will take place over the two days of the Aerophilia 2023 event.",
        "Reels submitted for the competition must be limited to a maximum duration of 30 seconds",
        " All reels must be original creations of the participants. Plagiarism or the use of others' work is strictly prohibited.",
        " Participants must submit the video through email as a Google Drive file. ",
        "Each participant must post the reel it on their Social Media  tagging @aerophilia_2023 and @sahyadri_college and using the #Aerophilia_2023.",
        "The winners will be announced on the ﬁnal day of the event",
        "Each participant will get an option to submit all their photos and videos shot during the event on the ﬁnal day.",
      ],
    },

    contactDetails: [
      {
        name: "HARSHITH K",
        phoneNumber: "+91 96063 28118",
      },

      {
        name: "KAUSHIK",
        phoneNumber: "+91 76765 86643",
      },
    ],
    link: "https://drive.google.com/file/d/149S9nZQ8M-1gk8eyIP9yTCeWrE28E0uw/view?usp=drivesdk",
  },
  {
    type: "Non technical",
    name: "EClash",
    day: "1 & 2",
    // time: "Online",
    // venue: "Online",
    date: "4th to 6th Dec",
    maxlimit: 4,
    mode: "online",
    image: eclash,
    prize: "10000",
    registrations: [
      "Registration fee: ₹ 200 per team",
      "Keep payment screenshot for verification",
      "Google form will be shared once after registeration.",
    ],
    teamReq: [
      "Maximum 4 members in a team.",
      "All the team members must be full-time students at an accredited University/College/School.",
      "Students from multiple Colleges can form a team.",
      "There are no restrictions on the number of teams from the same Institute.",
      "Each participant may only be a part of one team.",
    ],
    desc: "The competition involves virtual battle royale game i.e. Battlegrounds Mobile India(BGMI) where 100 players are dropped onto a map, and they must scavenge for weapons, equipment, and supplies while eliminating other players. The last surviving player or team wins the match.",
    smallDesc: "Unleash the Warrior Within!",
    format: {
      "Competetion Rules": [
        "In order to be eligible to participate in a Tournament as a player, an individual must have reached 16 years of age or older (i.e., the player has lived for at least 16 calendar years) as of the Registration end date.",
        "Ranking Requirement. All players on a Team’s roster must have held a ranking for BATTLEGROUNDS MOBILE INDIA of a level 25 and above Platinum V Tier, at the time of registration ends for any Official Competition.",
        "Players must be Indian Nationals to participate in the Tournament.",
        "Each Team must designate one player as its captain when completing the online registration process (“Team Captain”). The Team Captain will be responsible for all Team communications with Tournament Officials.",
        "Players may participate in Tournaments or Official Competitions only on mobile phones or approved handheld devices. Players may not use peripheral devices of any kind without explicit written approval of Tournament Officials (including tablets, adapters, controllers, Bluetooth keyboards, and mice). Players may not use an emulator to play on a PC or other device that is not a handheld device.",
        "After a player leaves the in-game lobby, his or her avatar must remain fully clothed in the game for the remainder of the match. Clothing exchanges for cosmetic purposes or comic effect are not permitted inside the game.",
      ],
    },

    contactDetails: [
      {
        name: "Gurucharan Nayak",
        phoneNumber: "+91 8590321676",
      },
      {
        name: "J S Ninad",
        phoneNumber: "+91 8880738765",
      },
    ],
  },
  {
    type: "Non technical",
    name: "FIRE POWER",
    day: "1 & 2",
    time: "Throughout the day",
    venue: "Offline",
    date: "8th and 9th Dec",
    image: firepower,
    maxlimit: 6,
    mode: "online",
    prize: "7000",
    registrations: [
      "Registration fee: ₹ 500 per team",
      "Spot Registerations are not allowed",
      "Keep payment screenshot for verification",
    ],
    teamReq: [
      "Each team can have a maximum of 6 members (5 players + 1 substitute)",
      "Each match is a custom game with Overtime Enabled",
    ],
    desc: "A Team-based tactical first-person shooter where two teams, Attackers and Defenders, compete. Attackers aim to plant a bomb at designated sites, while Defenders try to prevent it. Each player selects an Agent with unique abilities to achieve their objective in this competitive and strategic game. Multiple teams will progress forward in a single elimination tournament bracket for their battle to be rewarded with exciting prizes, swags and other benefits.",
    smallDesc: "Unleash the Warrior Within!",
    format: {
      "Competetion Rules": [
        "Team needs to be ready 10 mins prior to their designated time slots and assemble in the venue.",
        "Any kind of Threat Full comments/ Toxic Behavior in the venue may lead to penalty or even disqualification of the team.",
        "If a player is found to be cheating by abusing hacks, the player and his/her Team will be directly disqualified.",
        "A maximum of one Tactical Timeout is given for each team in each Half of the game.",
      ],
    },

    contactDetails: [
      {
        name: "Toshan S Maindan",
        phoneNumber: "+91 8549039062",
      },
    ],
    link: "https://drive.google.com/file/d/1hkyujTdMQNDP9tQIZxtTBX2Tp0DVrHKD/view?usp=sharing",
  },
  {
    type: "Non Technical",
    name: "CUBE CLASH",
    day: "1",
    fee: 100,
    time: "1.30 PM",
    venue: "Front of cafeteria",
    date: "8th Dec",
    maxlimit: 1,
    image: cube,
    prize: "Exciting prizes",
    registrations: ["Registration Fee: ₹100 per participant",
  "Register on spot"],

    desc: "Join us for the thrilling Cube Clash event, a cubing competition that celebrates speed and skill in solving the Rubik's Cube! Show off your solving prowess and compete against fellow cubers in a fun and challenging environment. This event promises excitement and fantastic prizes for the fastest solvers",
    smallDesc: "Rubik’s Cube Challenge",
    format: {
      "Rules and Regulations :": [
        "Open to all ages and skill levels.",
        "Standard 3x3 Rubik's Cube will be used. ",
        "The participants will get 15 seconds inspection time.",
        " Participants will be timed from the instant they pick up the cube until they place it down upon completion for accurate assessment of solving speed.",
        "Criteria for judgment: The participant who solves the cube in the shortest time emerges victorious.",
        "Timers and judges will oversee each solve for accuracy and adherence to rules.",
      ],
    },

    specialnote:
      "Cubes will be provided by the organizers. Participants are responsible for any malfunctions with the cubes during the competition. Decision of the organizers will be final in all aspects.",

    contactDetails: [
      {
        name: "Shrama Bhandary",
        phoneNumber: "+91 9945413269",
      },
    ],
  },

  {
    type: "Non Technical",
    name: "BON APPETIT",
    day: "2",
    fee: 100,
    time: "1.30 PM",
    venue: "Front of cafeteria",
    date: "9th Dec",
    maxlimit: 1,
    image: bon,
    prize: "Exciting prizes",
    registrations: ["Registration Fee: ₹100 per participant",
  "Register on spot"],

    desc: "Get ready for the ultimate eating challenge at bon appetit! Dive into a delicious showdown where participants compete for the title of the fastest and heartiest eater. This event promises a thrilling spectacle of food, fun, and fantastic prizes for those with an insatiable appetite.",

    format: {
      "Rules and Regulations :": [
        "Competition will be conducted in multiple rounds which will be revealed on the day of the event.",
        "Each participant will compete individually to consume the dish.",
        "In the event of a tie, additional rounds will determine the winner.",
        "Timers and judges will oversee the competition for adherence to rules.",
      ],
    },

    specialnote:
      "The food items provided for consumption will be arranged by the organizers. Participants are required to inform the organizers in advance about any allergies or dietary restrictions.All food items offered will adhere strictly to a vegetarian diet.Decision of the organizers will be final in all aspects.",

    contactDetails: [
      {
        name: "Shrama Bhandary",
        phoneNumber: "+91 9945413269",
      },
    ],
  },

  {
    name: "ALSO EXPLORE",
    date: "CODEBLAZE",
    image: codeblaze,
    smallDesc: "CODEBLAZE",
    time: "",
  },
];
