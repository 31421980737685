import React from "react";

const RF = () => {
  return (
    <div>
      <p className="text-black">
        Refund and Cancellation<br></br>
        Once the registration is successful,no refund or cancellation will be
        allowed . The registration charges are refunded only if the events which
        the participants have enrolled is cancelled for any reason.{" "}
      </p>
      <div></div>
    </div>
  );
};

export default RF;
